import styled from '@emotion/styled'
import dayjs from 'dayjs'
import * as paths from '../../../constants/path'
import React, { useCallback, useMemo, useState } from 'react'
import { useRouter } from '../../../../utils/helper'
import { Authorize } from '../../../components/Authorize'
import Button from '../../../components/common/Button'
import Text from '../../../components/common/Text'
import ListPage from '../../../components/templates/ListPage'

import { ROLE } from '../../../services/user/user-typed'
import { useGetGoldTopUp } from '../../../services/gold-top-up/gold-top-up-query'
import ModalExportGoldTopUp from './ModalExportGoldTopUp'
import { Box } from '@material-ui/core'
import { numberWithCommas } from '../../../../utils/core'
import { IGetGoldTopUpDetailResponse } from '../../../services/gold-top-up/gold-top-up-typed'

const ButtonStyled = styled(Button)`
  padding: 7px 13px;
`

const SummaryLayout = styled.ul`
  padding-inline-start: 10px;

  > li {
    white-space: nowrap;
  }
`

const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 16px;
`

const GoldTopUpList = () => {
  const { push, query } = useRouter()
  const [openExportModal, setOpenExportModal] = useState(false)
  const { q, page } = query
  const { data: goldTopUp } = useGetGoldTopUp({ q, page })

  const handleCreate = useCallback(() => {
    push(paths.createGoldTopUp())
  }, [push])

  const onViewClick = useCallback(
    record => {
      const routeParam: any = {
        id: record.id,
      }

      push(paths.goldTopUpDetail({ routeParam }))
    },
    [push],
  )

  const onOpenExportModal = useCallback(() => {
    setOpenExportModal(true)
  }, [])

  const onCloseExportModal = useCallback(() => {
    setOpenExportModal(false)
  }, [])

  const columns = useMemo(() => {
    return [
      {
        title: 'Campaign name',
        dataIndex: 'campaignName',
      },
      {
        title: 'Prefix',
        dataIndex: 'prefix',
        render: (v: string) => {
          return (
            <div
              style={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
                maxWidth: '150px',
              }}
            >
              {v}
            </div>
          )
        },
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        render: (text: string) => {
          return <Text>{text}</Text>
        },
      },
      {
        title: 'Type',
        dataIndex: 'type',
        render: (text: string) => {
          return (
            <Text>
              {text
                .toUpperCase()
                .split('_')
                .join(' ')}
            </Text>
          )
        },
      },
      // {
      //   title: 'Cap per transaction',
      //   dataIndex: 'capacityPerTransaction',
      // },
      // {
      //   title: 'Cap per campaign',
      //   dataIndex: 'capacityPerCampaign',
      // },
      // {
      //   title: 'Max frequency',
      //   dataIndex: 'maxFrequency',
      // },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        render: (text: string, record: { startDate: string }) => {
          const { startDate } = record
          return startDate ? (
            <Text>{dayjs(startDate).format('D MMMM  YYYY HH:mm ')}</Text>
          ) : (
            <Text>{`Not starDate yet`}</Text>
          )
        },
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        render: (text: string, record: { endDate: string }) => {
          const { endDate } = record
          return endDate ? (
            <Text>{dayjs(endDate).format('D MMMM  YYYY HH:mm ')}</Text>
          ) : (
            <Text>{`Not endDate yet`}</Text>
          )
        },
      },
      {
        title: 'Accumulate',
        dataIndex: 'accumulateValue',
        render: (text: string, record: { capacityPerCampaign: number }) => {
          const { capacityPerCampaign } = record
          return <Text>{`${text} / ${capacityPerCampaign}`}</Text>
        },
      },
      {
        title: 'Progress',
        dataIndex: 'summary',
        render: (_: unknown, record: IGetGoldTopUpDetailResponse) => {
          const { summary } = record
          return (
            <SummaryLayout
              style={{
                paddingInlineStart: '10px',
              }}
            >
              <li>Total: {numberWithCommas(summary.total, 0)}</li>
              <li>Pending: {numberWithCommas(summary.countPending, 0)}</li>
              <li>Processing: {numberWithCommas(summary.countProcessing, 0)}</li>
              <li>Success: {numberWithCommas(summary.countSuccess, 0)}</li>
              <li>Fail: {numberWithCommas(summary.countFail, 0)}</li>
            </SummaryLayout>
          )
        },
      },
      {
        title: '',
        dataIndex: 'action',
        render: (text: string, record: {}) => (
          <Button onClick={() => onViewClick(record)}>View</Button>
        ),
      },
    ]
  }, [onViewClick])

  const setQueryParam = useCallback(
    params => {
      push(paths.goldTopUp({ queryParam: { ...query, ...params } }))
    },
    [push, query],
  )
  const onSearch = useCallback(
    text => {
      setQueryParam({ q: text, page: 1 })
    },
    [setQueryParam],
  )

  const onPageChange = useCallback(
    page => {
      setQueryParam({ page })
    },
    [setQueryParam],
  )
  return (
    <>
      <ListPage
        topbar={
          <Layout>
            <Authorize role={ROLE.SUPER_ADMIN}>
              <ButtonStyled variant="outline-warning" onClick={onOpenExportModal}>
                Export
              </ButtonStyled>
              <Box width={'8px'} />
              <ButtonStyled variant="primary" onClick={handleCreate}>
                Create
              </ButtonStyled>
            </Authorize>
          </Layout>
        }
        tableProps={{
          columns,
          data: goldTopUp?.items || [],
          onPageChange,
          pagination: goldTopUp?.meta,
        }}
        searchInputProps={{ onSearch, value: query.q, placeholder: 'Search title' }}
      />

      <ModalExportGoldTopUp visible={openExportModal} onClose={onCloseExportModal} />
    </>
  )
}

export default GoldTopUpList

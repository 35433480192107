import { useTranslation } from 'react-i18next'
import { useToastForReactQuery } from '../../../../utils/custom-hooks'
import React, { useCallback, useMemo, useState } from 'react'
import { EnumGiveawayUnit, ICreateGiveawayBody } from '../../../services/giveaway/giveaway-typed'
import { useCreateGiveaway } from '../../../services/giveaway/giveaway-query'
import { Field, Form } from 'react-final-form'
import styled from '@emotion/styled'
import Card from '../../../components/common/Card'
import { InputField, NumberInputField, SelectField } from '../../../components/fields'
import Button from '../../../components/common/Button'
import { Modal } from '@material-ui/core'
import Text from '../../../components/common/Text'
import { useGetSearchUser } from '../../../services/user/user-query'
import { useOtpMobileByToken } from '../../../services/otp/otp-query'
import { OtpAction } from '../../../services/otp/otp-types'
import OTPModal from '../../../components/common/OTPModal'

const ContentLayout = styled(Card)`
  width: 500px;
  min-height: 200px;
  padding: 30px 80px 20px 80px;

  .card-body {
    display: flex;
    flex-flow: column;
    grid-row-gap: 10px;
  }
`

const InputFieldStyled = styled(InputField)`
  &.MuiFormControl-root {
    width: 100%;
    margin-top: 0;
  }
`

const ModalStyled = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

type GiveawayFormValues = {
  tmnUser: { label: string; value: string }
  prefix: string
  amount: number
  unit: { label: string; value: EnumGiveawayUnit }
  campaign: string
  tmnTransactionId?: string
}

type ModalCreateGiveawayProps = {
  visible: boolean
  onClose: () => void
}

type OTPState = {
  id: string
  refCode: string
}

const ModalCreateGiveaway = (props: ModalCreateGiveawayProps) => {
  const { visible, onClose } = props

  const toast = useToastForReactQuery()
  const { t } = useTranslation()
  const { mutateAsync: requestOtp } = useOtpMobileByToken()
  const [otpErrorMessage, setOtpErrorMessage] = useState('')
  const [otpModalVisible, setOtpModalVisible] = useState(false)
  const [otpState, setOtpState] = useState<OTPState | null>(null)
  const [giveAwayData, setGiveAwayData] = useState<GiveawayFormValues | null>(null)

  const { mutateAsync: createGiveaway } = useCreateGiveaway()
  const [search, setSearch] = useState<any>(null)
  const { data: users } = useGetSearchUser({ limit: 5, q: search }, visible)

  const onSubmit = useCallback(
    async (values: GiveawayFormValues) => {
      try {
        const otpResponse = await requestOtp({
          action: OtpAction.MANUAL_GIVE,
        })

        setOtpState({ id: otpResponse.otpId, refCode: otpResponse.refCode }) // Assuming otpResponse contains an otpRef
        setGiveAwayData(values)
        setOtpModalVisible(true)
        setOtpErrorMessage('') // Reset OTP error message
        onClose()
      } catch (error) {
        onClose()
        toast.open(t(`${error}`), {
          type: 'error',
          autoClose: 3 * 1000,
        })
      }
    },
    [onClose, requestOtp, t, toast],
  )
  const handleOTPConfirm = useCallback(
    async (otp: string) => {
      if (giveAwayData) {
        const createBody: ICreateGiveawayBody = {
          amount: giveAwayData.amount,
          campaign: giveAwayData.campaign,
          prefix: giveAwayData.prefix,
          tmnId: giveAwayData.tmnUser?.value,
          unit: giveAwayData.unit.value,
          otp,
          otpId: otpState?.id,
          tmnTransactionId: giveAwayData.tmnTransactionId,
        }
        createGiveaway(createBody, {
          onSuccess: () => {
            toast.open('Success', {
              type: 'success',
              autoClose: 3 * 1000,
            })
            onClose()
            setOtpModalVisible(false)
          },
          onError: error => {
            if (error === '422-022') {
              setOtpErrorMessage(t(error))
            } else {
              toast.open('Can not create give away', {
                type: 'error',
                autoClose: 3 * 1000,
              })
              setOtpModalVisible(false)
            }
          },
        })
      }
    },
    [giveAwayData, otpState, createGiveaway, toast, onClose, t],
  )
  const validateRequired = useCallback((value: string) => (value ? undefined : 'required'), [])

  const validatePrefixField = useCallback((value: string) => {
    const regex = /^[A-Z0-9]{1,60}$/

    if (!value) return 'required'

    if (!regex.test(value)) {
      return 'Only uppercase letters and numbers, 1-60 characters allowed'
    }
    return undefined
  }, [])

  const unitOptions = useMemo(() => {
    return Object.keys(EnumGiveawayUnit).map(unit => {
      const value = EnumGiveawayUnit[unit as EnumGiveawayUnit]
      return {
        label: unit.toUpperCase(),
        value: value,
      }
    })
  }, [])

  const userOptions = useMemo(() => {
    return users?.items.map(
      (e): BaseOptionType => {
        return {
          label: `${e.firstnameTh} ${e.lastnameTh}`,
          value: e.tmnId,
        }
      },
    )
  }, [users])

  const onSearchChange = useCallback((input: string) => {
    setSearch(input)
  }, [])

  return (
    <>
      <ModalStyled open={visible} onClose={onClose}>
        <div>
          <Form<GiveawayFormValues>
            onSubmit={onSubmit}
            initialValues={{
              unit: unitOptions[0],
            }}
          >
            {({ handleSubmit, dirty, submitting, valid, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <ContentLayout>
                    <div>
                      <Text>CAMPAIGN NAME</Text>
                      <Field
                        name="campaign"
                        component={InputFieldStyled}
                        validate={validateRequired}
                      />
                    </div>
                    <div>
                      <Text>PREFIX</Text>
                      <Field
                        name="prefix"
                        component={InputFieldStyled}
                        validate={validatePrefixField}
                      />
                    </div>
                    <div>
                      <Text>USER</Text>
                      <SelectField
                        name="tmnUser"
                        placeholder=""
                        options={userOptions}
                        onInputChange={onSearchChange}
                      />
                    </div>
                    <div>
                      <Text>AMOUNT</Text>
                      <NumberInputField
                        name="amount"
                        allowNegative={false}
                        allowLeadingZeros={false}
                        allowNull={false}
                        decimalScale={values?.unit?.value === EnumGiveawayUnit.baht ? 2 : 4}
                        validate={validateRequired}
                      />
                    </div>
                    <div>
                      <SelectField
                        name="unit"
                        label="UNIT"
                        placeholder="Select Unit"
                        options={unitOptions}
                        validate={validateRequired}
                      />
                    </div>
                    <div>
                      <Text>Tmn Transaction Id</Text>
                      <Field name="tmnTransactionId" component={InputFieldStyled} />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        padding: '20px',
                        gridColumnGap: '10px',
                      }}
                    >
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={!dirty || submitting || !valid}
                      >
                        Confirm
                      </Button>
                      <Button variant="outline-secondary" onClick={onClose}>
                        Cancel
                      </Button>
                    </div>
                  </ContentLayout>
                </form>
              )
            }}
          </Form>
        </div>
      </ModalStyled>
      <OTPModal
        show={otpModalVisible}
        handleClose={() => setOtpModalVisible(false)}
        handleConfirm={handleOTPConfirm}
        otpRef={otpState?.refCode}
        errorMessage={otpErrorMessage}
      />
    </>
  )
}

export default ModalCreateGiveaway

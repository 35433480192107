import React, { useCallback, useMemo } from 'react'
import { useRouter } from '../../../../utils/helper'
import ListPage from '../../../components/templates/ListPage'
import * as paths from '../../../constants/path'
import Button from '../../../components/common/Button'
import Text from '../../../components/common/Text'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import {
  EnumGiveawayStatus,
  EnumGiveawayUnit,
  IGiveaway,
} from '../../../services/giveaway/giveaway-typed'
import { numberWithCommas } from '../../../../utils/core'
import { ColumnsProps } from '../../../components/common/Table'
import { useGetGoldTopUpGiveaways } from '../../../services/gold-top-up/gold-top-up-query'
import { Box } from '@material-ui/core'
import { ClassNames } from '@emotion/react'
import { OnChangeValue } from 'react-select'
import { values } from 'lodash'
import Select from '../../../components/common/Select'

interface IGoldTopUpGiveawaysProps {
  goldTopUpTemplateId: string
}
export const GoldTopUpGiveaways = (props: IGoldTopUpGiveawaysProps) => {
  const { goldTopUpTemplateId } = props
  const { query } = useRouter()
  const history = useHistory()
  const { q, page } = query

  const { data: giveaways } = useGetGoldTopUpGiveaways(goldTopUpTemplateId, {
    q,
    page,
    limit: 5,
    status: query?.status || undefined,
  })

  const onClickViewDetail = useCallback(
    (giveawayId: string) => {
      history.push(paths.rewardDetail({ routeParam: { giveawayId } }))
    },
    [history],
  )

  const columns = useMemo((): ColumnsProps => {
    return [
      {
        title: 'Campaign name',
        dataIndex: 'campaignName',
      },
      {
        title: 'Prefix',
        dataIndex: 'prefix',
        render: (v: string) => {
          return (
            <div
              style={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
                maxWidth: '150px',
              }}
            >
              {v}
            </div>
          )
        },
      },
      { title: 'Tmn Id', dataIndex: 'user.tmnId' },
      {
        title: 'Amount',
        dataIndex: 'amount',
        render: (v: string, record) => (
          <div>{numberWithCommas(+v, record.unit === EnumGiveawayUnit.baht ? 2 : 4)}</div>
        ),
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
      },
      {
        title: 'Create At',
        dataIndex: 'createdAt',
        render: (text: string) => {
          return <Text>{dayjs(text).format('D MMMM  YYYY HH:mm ')}</Text>
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (text: string) => {
          //const { isActive } = record
          return <Text>{text}</Text>
        },
      },
      {
        title: '',
        dataIndex: 'action',
        render: (text: string, giveaway: IGiveaway) => {
          console.debug({ giveaway })
          return <Button onClick={onClickViewDetail.bind(null, giveaway.id)}>View</Button>
        },
      },
    ]
  }, [onClickViewDetail])

  const setQueryParam = useCallback(
    params => {
      history.replace(
        paths.goldTopUpDetail({
          queryParam: { ...query, ...params },
          routeParam: {
            id: goldTopUpTemplateId,
          },
        }),
      )
    },
    [goldTopUpTemplateId, history, query],
  )

  const onSearch = useCallback(
    text => {
      setQueryParam({ q: text, page: 1 })
    },
    [setQueryParam],
  )
  const onPageChange = useCallback(
    page => {
      setQueryParam({ page })
    },
    [setQueryParam],
  )

  const onFilterChange = useCallback(
    (value: OnChangeValue<BaseOptionType, false>) => {
      setQueryParam({ status: value?.value, page: 1 })
    },
    [setQueryParam],
  )

  const kycFilterOptions = useMemo(() => {
    return values(EnumGiveawayStatus).map(
      (e): BaseOptionType => {
        return {
          label: e
            .toString()
            .toUpperCase()
            .replaceAll('_', ' '),
          value: e.toString(),
        }
      },
    )
  }, [])

  const filterValue = useMemo(() => {
    const status = query?.status
    return kycFilterOptions.find(e => e.value === status)
  }, [kycFilterOptions, query])

  return (
    <>
      <ListPage
        topbar={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gridColumnGap="8px"
            width="100%"
          >
            <ClassNames>
              {({ css }) => (
                <Select
                  className={css`
                    min-width: 160px;
                  `}
                  placeholder="Filter Status"
                  value={filterValue}
                  options={kycFilterOptions}
                  onChange={onFilterChange}
                  isClearable
                />
              )}
            </ClassNames>
          </Box>
        }
        tableProps={{
          columns,
          data: giveaways?.items || [],
          onPageChange,
          pagination: giveaways?.meta,
        }}
        searchInputProps={{ onSearch, value: query.q, placeholder: 'Search' }}
      />
    </>
  )
}

import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'
import { downloadFile } from '../../../utils/helper'
import { IGiveawayList } from '../giveaway/giveaway-typed'
import {
  GetTopUpTemplateGiveawayParams,
  ICreateGoldTopUpBody,
  IExportGoldTopUpReportParams,
  IGetGoldTopUpDetailResponse,
  IGetListGoldTopUpResponse,
} from './gold-top-up-typed'

const GOLD_TOP_UP_PATH = 'internal/top-up-templates'
const GOLD_TOP_UP_REPORT_PATH = `${GOLD_TOP_UP_PATH}/report`

export const useCreateGoldTopUp = () => {
  const queryClient = useQueryClient()
  return useMutation<any, string, ICreateGoldTopUpBody>(
    async body => {
      const { data } = await api.savvyGold.post(`${GOLD_TOP_UP_PATH}`, {
        ...body,
      })
      return data
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([GOLD_TOP_UP_PATH])
      },
    },
  )
}

export const useGetGoldTopUp = (params?: { q?: string; page?: number }) => {
  return useQuery(
    [GOLD_TOP_UP_PATH, params],
    async () => {
      const { data } = await api.savvyGold.get<IGetListGoldTopUpResponse>(GOLD_TOP_UP_PATH, {
        page: params?.page,
        limit: 10,
        q: params?.q,
      })
      const result = data.data

      return result
    },
    {
      keepPreviousData: true,
      refetchInterval: 5 * 1000,
    },
  )
}

export const useGetGoldTopUpById = (id: string) => {
  return useQuery(
    [GOLD_TOP_UP_PATH, id],
    async () => {
      const { data } = await api.savvyGold.get<IGetGoldTopUpDetailResponse>(
        `${GOLD_TOP_UP_PATH}/${id}`,
      )
      const result = data.data

      return result
    },
    {
      enabled: !!id,
    },
  )
}

export const useGetGoldTopUpGiveaways = (id: string, params: GetTopUpTemplateGiveawayParams) => {
  return useQuery(
    [GOLD_TOP_UP_PATH, id, params],
    async () => {
      const { data } = await api.savvyGold.get<IGiveawayList>(
        `${GOLD_TOP_UP_PATH}/${id}/giveaways`,
        params,
      )
      const result = data.data
      return result
    },
    {
      enabled: !!id,
      refetchInterval: 5 * 1000,
    },
  )
}

export const useUpdateGoldTopUp = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: ICreateGoldTopUpBody) => {
      const { data } = await api.savvyGold.patch(`${GOLD_TOP_UP_PATH}/${id}`, params)
      return data
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([GOLD_TOP_UP_PATH])
      },
    },
  )
}

export const useDeleteGoldTopUp = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    async () => {
      const { data } = await api.savvyGold.delete(`${GOLD_TOP_UP_PATH}/${id}`)
      return data
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([GOLD_TOP_UP_PATH])
      },
    },
  )
}

export const useGetGoldTopUpReport = () => {
  return useMutation<any, string, IExportGoldTopUpReportParams>(
    async (params: IExportGoldTopUpReportParams) => {
      const { startDate, endDate, ...restParams } = params
      const res = await api.savvyGold.getFile(`${GOLD_TOP_UP_REPORT_PATH}`, {
        startDate: dayjs(startDate).format(),
        endDate: dayjs(endDate).format(),
        ...restParams,
      })
      downloadFile(res)
    },
  )
}

import styled from '@emotion/styled'
import ConfirmCard from '../../../components/common/Card'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { first, isEmpty } from 'lodash'
import { Authorize, validateCorrectRole } from '../../../components/Authorize'
import { ROLE } from '../../../services/user/user-typed'
import { useCurrentUser } from '../../../services/user/user-query'
import { Field, Form } from 'react-final-form'
import Text from '../../../components/common/Text'
import {
  CheckBoxField,
  DatePickerField,
  InputField,
  NumberInputField,
  SelectField,
} from '../../../components/fields'
import { FormControlLabel, makeStyles, Modal } from '@material-ui/core'
import Button from '../../../components/common/Button'
import {
  EnumTopUpTemplateType,
  EnumTopUpUnit,
  ICreateGoldTopUpBody,
} from '../../../services/gold-top-up/gold-top-up-typed'
import { cp, createValidation, maxLength, required } from '../../../../utils/field-validation'
import {
  useCreateGoldTopUp,
  useDeleteGoldTopUp,
  useGetGoldTopUpById,
  useGetGoldTopUpGiveaways,
  useUpdateGoldTopUp,
} from '../../../services/gold-top-up/gold-top-up-query'
import { useToastForReactQuery, useVisible } from '../../../../utils/custom-hooks'
import { useHistory } from 'react-router-dom'
import * as paths from '../../../constants/path'
import { useRouter } from '../../../../utils/helper'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { GoldTopUpGiveaways } from './GoldTopUpGiveaways'

const CustomCard = styled(ConfirmCard)`
  width: 100%;
  max-width: 1000px;
`

const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`

export type ICreateGoldTopUpFormValues = {
  prefix: string
  campaignName: string
  startDate: string
  endtDate: string
  capacityPerTransaction: number
  capacityPerCampaign: number
  maxFrequency: number
  isActive: boolean
  unit: {
    label: string
    value: EnumTopUpUnit
  }
  type: {
    label: string
    value: EnumTopUpTemplateType
  }
}

const ButtonStyled = styled(Button)`
  padding: 7px 13px;
`

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const GoldTopUpDetail = () => {
  const [isViewMode, setIsViewMode] = useState(false)
  const [isDisabledPrefix, setIsDisabledPrefix] = useState(false)
  const { data: currentUser } = useCurrentUser()
  const { query } = useRouter()
  const goldTopUpId = useMemo(() => query.id, [query.id])
  const { data: goldTopUp } = useGetGoldTopUpById(goldTopUpId)
  const onConfirmModalVisible = useVisible()
  const onDeleteModalVisible = useVisible()
  const classes = useStyles()
  const history = useHistory()
  const toast = useToastForReactQuery()
  const { t } = useTranslation()

  const { mutate: updateGoldTopUp } = useUpdateGoldTopUp(goldTopUp?.id || '')
  const { mutate: creteGoldTopUp } = useCreateGoldTopUp()
  const { mutate: deleteGoldTopUp } = useDeleteGoldTopUp(goldTopUp?.id || '')

  useEffect(() => {
    if (goldTopUp?.id || !validateCorrectRole(ROLE.SUPER_ADMIN, currentUser?.role)) {
      setIsViewMode(true)
    }
  }, [currentUser, goldTopUp])

  const onGoBackToGoldTopUp = useCallback(() => {
    history.push(paths.goldTopUp())
  }, [history])

  const handleOpenConfirmModal = useCallback(() => {
    console.debug('active open')
    onConfirmModalVisible.open()
  }, [onConfirmModalVisible])

  const handleCloseConfirmModal = useCallback(() => {
    onConfirmModalVisible.close()
  }, [onConfirmModalVisible])

  const handleSubmit = useCallback(
    (values: ICreateGoldTopUpFormValues) => {
      console.debug(values)

      const body: ICreateGoldTopUpBody = {
        ...values,
        unit: values.unit.value,
        type: values.type.value,
        isActive: values.isActive || false,
      }

      if (query.id) {
        updateGoldTopUp(body, {
          onSuccess: () => {
            toast.open('Success', {
              type: 'success',
              autoClose: 3 * 1000,
            })
            handleCloseConfirmModal()
            onGoBackToGoldTopUp()
          },
          onError: (error: any) => {
            toast.open(t(`${error}`), {
              type: 'error',
              autoClose: 3 * 1000,
            })
            handleCloseConfirmModal()
          },
        })
      } else {
        creteGoldTopUp(body, {
          onSuccess: () => {
            toast.open('Success', {
              type: 'success',
              autoClose: 3 * 1000,
            })
            handleCloseConfirmModal()
            onGoBackToGoldTopUp()
          },
          onError: (error: any) => {
            toast.open(t(`${error}`), {
              type: 'error',
              autoClose: 3 * 1000,
            })
            handleCloseConfirmModal()
          },
        })
      }
    },
    [
      creteGoldTopUp,
      handleCloseConfirmModal,
      onGoBackToGoldTopUp,
      query.id,
      t,
      toast,
      updateGoldTopUp,
    ],
  )

  const validateRequired = useCallback((value: string) => (value ? undefined : 'required'), [])

  const renderTypeOption = useMemo(() => {
    return Object.keys(EnumTopUpTemplateType).map(
      (path): BaseOptionType => {
        const value = EnumTopUpTemplateType[path as keyof typeof EnumTopUpTemplateType]
        return {
          label: path
            .split('_')
            .join(' ')
            .toUpperCase(),
          value: `${value}`,
        }
      },
    )
  }, [])

  const renderUnitOption = useMemo(() => {
    return Object.keys(EnumTopUpUnit).map(
      (path): BaseOptionType => {
        const value = EnumTopUpUnit[path as keyof typeof EnumTopUpUnit]
        return {
          label: path.toUpperCase(),
          value: `${value}`,
        }
      },
    )
  }, [])

  const initialValues = useMemo(() => {
    let unitlabel
    let typeLabel
    Object.entries(EnumTopUpUnit).forEach(([key, value]) => {
      if (value === goldTopUp?.unit) {
        unitlabel = key
      }
    })

    Object.entries(EnumTopUpTemplateType).forEach(([key, value]) => {
      if (value === goldTopUp?.type) {
        typeLabel = key
          .split('_')
          .join(' ')
          .toLocaleUpperCase()
      }
    })
    return query.id
      ? {
          campaignName: goldTopUp?.campaignName,
          startDate: dayjs(goldTopUp?.startDate).format('YYYY-MM-DDTHH:mm'),
          endDate: dayjs(goldTopUp?.endDate).format('YYYY-MM-DDTHH:mm'),
          prefix: goldTopUp?.prefix,
          unit: unitlabel ? { label: unitlabel, value: goldTopUp?.unit } : undefined,
          type: typeLabel ? { label: typeLabel, value: goldTopUp?.type } : undefined,
          isActive: goldTopUp?.isActive,
          capacityPerTransaction: goldTopUp?.capacityPerTransaction,
          capacityPerCampaign: goldTopUp?.capacityPerCampaign,
          maxFrequency: goldTopUp?.maxFrequency,
        }
      : {}
  }, [goldTopUp, query.id])

  const validate = createValidation<ICreateGoldTopUpFormValues>(() => ({
    campaignName: cp(required('required title'), maxLength(50, 'Maximum length required 50')),
  }))

  const validatePrefixField = useCallback((value: string) => {
    const regex = /^[A-Z0-9]{16}$/

    if (!value) return 'required'

    if (!regex.test(value)) {
      return 'Only uppercase letters and numbers, 16 characters allowed '
    }
    return undefined
  }, [])

  const onClickEdit = useCallback(() => {
    setIsViewMode(false)
    setIsDisabledPrefix(true)
  }, [])

  const handleOpenDeleteModal = useCallback(() => {
    onDeleteModalVisible.open()
  }, [onDeleteModalVisible])

  const handleCloseDeleteModal = useCallback(() => {
    onDeleteModalVisible.close()
  }, [onDeleteModalVisible])

  const onClickDelete = useCallback(() => {
    deleteGoldTopUp()
    onGoBackToGoldTopUp()
  }, [deleteGoldTopUp, onGoBackToGoldTopUp])

  return (
    <>
      <Authorize role={ROLE.SUPER_ADMIN}>
        {query.id && (
          <div
            style={{
              marginBottom: '16px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <ButtonStyled
              variant="warning"
              onClick={onClickEdit}
              style={{
                marginRight: '16px',
              }}
            >
              Edit
            </ButtonStyled>
            {/* <ButtonStyled variant="danger" onClick={handleOpenDeleteModal}>
              Delete
            </ButtonStyled> */}
          </div>
        )}
      </Authorize>
      <CustomCard>
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
          render={({ handleSubmit, invalid, values, form, valid, dirty, error }) => {
            console.log(values, error)

            return (
              <ContentLayout>
                <div style={{ paddingBottom: '10px' }}>
                  <SelectField
                    name="type"
                    label="Type"
                    placeholder="Select Type"
                    options={renderTypeOption}
                    disabled={isViewMode}
                    onChange={type => {
                      if (type) {
                        form.change('type', {
                          label: type.label,
                          value: type.value as EnumTopUpTemplateType,
                        })
                      }

                      if (type && type.value === EnumTopUpTemplateType.NOTI_GOLD_BACK) {
                        form.change('unit', {
                          label: 'BAHT',
                          value: EnumTopUpUnit.BAHT,
                        })
                      } else {
                        form.change('unit', undefined)
                      }
                    }}
                    validate={validateRequired}
                  />
                </div>

                <div style={{ paddingBottom: '10px' }}>
                  <SelectField
                    name="unit"
                    label="Unit"
                    placeholder="Select Unit"
                    options={renderUnitOption}
                    disabled={
                      isViewMode || values?.type?.value === EnumTopUpTemplateType.NOTI_GOLD_BACK
                    }
                    validate={validateRequired}
                  />
                </div>

                <div style={{ paddingBottom: '10px' }}>
                  <Text>Campaign Name</Text>
                  <Field
                    name="campaignName"
                    component={InputField}
                    placeholder="Campaign Name"
                    id="campaignName"
                    disabled={isViewMode}
                    style={{ width: '100%' }}
                  />
                </div>

                <div style={{ paddingBottom: '10px' }}>
                  <Text>Prefix</Text>
                  <Field
                    name="prefix"
                    component={InputField}
                    placeholder="Prefix"
                    id="prefix"
                    disabled={isViewMode || isDisabledPrefix}
                    validate={validatePrefixField}
                    onChange={(e: { target: { value: string } }) => {
                      form.change('prefix', e.target.value.toUpperCase())
                    }}
                    style={{ width: '100%' }}
                  />
                </div>

                <div style={{ display: 'flex', paddingBottom: '10px' }}>
                  <div>
                    <Text>Start date</Text>
                    <Field
                      name="startDate"
                      component={DatePickerField}
                      id="startDate"
                      label=""
                      type="datetime-local"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={isViewMode}
                    />
                  </div>
                  <div style={{ marginLeft: 16 }}>
                    <Text>End date</Text>
                    <Field
                      name="endDate"
                      component={DatePickerField}
                      id="endDate"
                      label=""
                      type="datetime-local"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={isViewMode}
                    />
                  </div>
                </div>

                <div style={{ paddingBottom: '10px', width: '300px' }}>
                  <Text>Capacity per Transaction</Text>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                      alignItems: 'flex-end',
                    }}
                  >
                    <NumberInputField
                      name="capacityPerTransaction"
                      allowNegative={false}
                      allowLeadingZeros={false}
                      allowNull={false}
                      disabled={isViewMode}
                      decimalScale={values?.unit?.value === EnumTopUpUnit.BAHT ? 2 : 4}
                      validate={validateRequired}
                    />
                    <Text>
                      {values?.unit?.value === EnumTopUpUnit.BAHT
                        ? 'baht'
                        : values?.unit?.value === EnumTopUpUnit.GRAM
                        ? 'gram'
                        : undefined}
                    </Text>
                  </div>
                </div>

                <div style={{ paddingBottom: '10px', width: '300px' }}>
                  <Text>Capacity per Campaign</Text>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                      alignItems: 'flex-end',
                    }}
                  >
                    <NumberInputField
                      name="capacityPerCampaign"
                      allowNegative={false}
                      allowLeadingZeros={false}
                      disabled={isViewMode}
                      allowNull={false}
                      decimalScale={values?.unit?.value === EnumTopUpUnit.BAHT ? 2 : 4}
                      validate={validateRequired}
                    />
                    <Text>
                      {values?.unit?.value === EnumTopUpUnit.BAHT
                        ? 'baht'
                        : values?.unit?.value === EnumTopUpUnit.GRAM
                        ? 'gram'
                        : undefined}
                    </Text>
                  </div>
                </div>

                <div style={{ paddingBottom: '10px', width: '300px' }}>
                  <Text>Limit per SG User ID</Text>
                  <NumberInputField
                    name="maxFrequency"
                    allowNegative={false}
                    allowLeadingZeros={false}
                    disabled={isViewMode}
                    allowNull={false}
                    // decimalScale={values?.unit?.value === EnumGiveawayUnit.baht ? 2 : 4}
                    validate={validateRequired}
                  />
                </div>

                <FormControlLabel
                  control={
                    <Field name="isActive" component={CheckBoxField} disabled={isViewMode} />
                  }
                  label="Active"
                />

                <Button
                  variant="primary"
                  onClick={handleOpenConfirmModal}
                  disabled={!valid || isViewMode}
                  style={{ width: '100px' }}
                >
                  Confirm
                </Button>

                <Modal
                  className={classes.modal}
                  open={onConfirmModalVisible.visible}
                  onClose={onConfirmModalVisible.close}
                >
                  <ConfirmCard
                    style={{
                      display: 'flex',
                      width: '300px',
                      padding: '30px',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Text>Confirm</Text>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignSelf: 'center',
                        paddingTop: '10px',
                      }}
                    >
                      <Button
                        style={{
                          alignSelf: 'center',
                          marginRight: '20px',
                        }}
                        onClick={handleSubmit}
                      >
                        Yes
                      </Button>
                      <Button
                        style={{
                          alignSelf: 'center',
                          backgroundColor: '#FF0000',
                          borderColor: '#FF0000',
                        }}
                        onClick={onConfirmModalVisible.close}
                      >
                        No
                      </Button>
                    </div>
                  </ConfirmCard>
                </Modal>
                <Modal
                  className={classes.modal}
                  open={onDeleteModalVisible.visible}
                  onClose={onDeleteModalVisible.close}
                >
                  <ConfirmCard
                    style={{
                      display: 'flex',
                      width: '300px',
                      padding: '30px',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Text>Confirm Delete</Text>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignSelf: 'center',
                        paddingTop: '10px',
                      }}
                    >
                      <Button
                        style={{
                          alignSelf: 'center',
                          marginRight: '20px',
                        }}
                        onClick={onClickDelete}
                      >
                        Yes
                      </Button>
                      <Button
                        style={{
                          alignSelf: 'center',
                          backgroundColor: '#FF0000',
                          borderColor: '#FF0000',
                        }}
                        onClick={handleCloseDeleteModal}
                      >
                        No
                      </Button>
                    </div>
                  </ConfirmCard>
                </Modal>
              </ContentLayout>
            )
          }}
        />
      </CustomCard>
      <div style={{ marginTop: '16px' }} />
      <GoldTopUpGiveaways goldTopUpTemplateId={goldTopUpId} />
    </>
  )
}

export default GoldTopUpDetail

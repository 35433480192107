import React, { useEffect, useRef } from 'react'

type AuthInputProps = {
  input: any
  meta: any
  autoFocus?: boolean
  [key: string]: any
}

const getInputClasses = (meta: any) => {
  if (meta.error && meta.touched) {
    return 'is-invalid'
  }
  if (!meta.error && meta.touched) {
    return 'is-valid'
  }
  return ''
}

const NoLabelInput = (props: AuthInputProps) => {
  const { input, meta, autoFocus, ...componentProps } = props
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (ref.current && autoFocus) {
      ref.current.focus()
    }
  }, [autoFocus])

  return (
    <>
      <input
        {...input}
        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(meta)}`}
        {...componentProps}
        ref={ref}
      />
      {meta.error && meta.touched && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block" style={{ color: 'red' }}>
            {meta.error}
          </div>
        </div>
      )}
    </>
  )
}

export default NoLabelInput

import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import NoLabelInput from './NoLabelInput'
import zIndex from '@material-ui/core/styles/zIndex'

interface OTPModalProps {
  show: boolean
  handleClose: () => void
  handleConfirm: (otp: string) => void
  otpRef?: string
  errorMessage?: string
}

const OTPModal: React.FC<OTPModalProps> = ({
  show,
  handleClose,
  handleConfirm,
  otpRef,
  errorMessage,
}) => {
  const onSubmit = (values: { otp: string }) => {
    handleConfirm(values.otp)
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Enter OTP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <Field placeholder="OTP" name="otp" component={NoLabelInput} autoFocus />
              <div style={{ color: '#666', textAlign: 'right' }}>ref: {otpRef}</div>
              {errorMessage && <div className="text-danger ">{errorMessage}</div>}
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Button variant="primary" type="submit" style={{ marginRight: '10px' }}>
                  Submit
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </form>
          )}
        />
      </Modal.Body>
    </Modal>
  )
}

export default OTPModal

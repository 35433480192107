import { Dayjs } from 'dayjs'
import { EnumGiveawayStatus } from '../giveaway/giveaway-typed'

export enum EnumTopUpTemplateType {
  GOLD_TOP_UP = 'gold_top_up',
  NOTI_GOLD_BACK = 'noti_gold_back',
}

export enum EnumGoldTopUpStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAIL = 'fail',
}

export enum EnumTopUpUnit {
  GRAM = 'gram',
  BAHT = 'baht',
}

export type IExportGoldTopUpReportParams = {
  prefix?: string
  startDate: Dayjs
  endDate: Dayjs
  status?: EnumGoldTopUpStatus
  unit?: EnumTopUpUnit
}

export interface IGetTopUpTemplatesSummary {
  topUpTemplateId: string
  total: number
  countPending: number
  countProcessing: number
  countSuccess: number
  countFail: number
}

export type IGetGoldTopUpDetailResponse = {
  id: string
  prefix: string
  campaignName: string
  startDate: string
  endDate: string
  capacityPerTransaction: number
  capacityPerCampaign: number
  maxFrequency: number
  isActive: boolean
  unit: EnumTopUpUnit
  type: EnumTopUpTemplateType
  accumulateValue?: number
  summary: IGetTopUpTemplatesSummary
}

export type IPageMeta = {
  currentPage: number
  itemCount: number
  itemsPerPage: number
  totalItems: number
  totalPages: number
}

export type ICreateGoldTopUpBody = {
  prefix: string
  campaignName: string
  startDate: string
  endtDate: string
  capacityPerTransaction: number
  capacityPerCampaign: number
  maxFrequency: number
  isActive: boolean
  unit: EnumTopUpUnit
  type: EnumTopUpTemplateType
}

export type IGetListGoldTopUpResponse = {
  items: IGetGoldTopUpDetailResponse[]
  meta: IPageMeta
}

export interface GetTopUpTemplateGiveawayParams {
  limit?: number
  page?: number
  q?: string
  status?: EnumGiveawayStatus
}

import React from 'react'
import { Field, FormRenderProps } from 'react-final-form'

import NoLabelInput from '../../../components/common/NoLabelInput'
import {
  createValidation,
  required,
  cp,
  number,
  maxLength,
  minLength,
} from '../../../../utils/field-validation'
import { Otp } from '../../../services/otp/otp-types'

export type OtpFormValue = {
  otp: string
}

export const validation = createValidation<OtpFormValue>(value => ({
  otp: cp(
    required('required OTP'),
    number('รูปแบบไม่ถูกต้อง'),
    maxLength(6, 'รูปแบบไม่ถูกต้อง'),
    minLength(6, 'รูปแบบไม่ถูกต้อง'),
  ),
}))

export default (
  props: FormRenderProps<any> & { onCancel: () => void; errorMessage: string; otpData?: Otp },
) => {
  const { submitting, onCancel, errorMessage, otpData, invalid } = props

  return (
    <>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Email Validation</h3>
        <p className="text-muted font-weight-bold">Enter OPT number from your email</p>
      </div>
      {errorMessage && (
        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">{errorMessage}</div>
        </div>
      )}
      <div className="form-group fv-plugins-icon-container">
        <Field placeholder="OTP" name="otp" component={NoLabelInput} autoFocus />

        <div style={{ textAlign: 'right' }}>ref: {otpData?.refCode}</div>
      </div>
      <div className="form-group d-flex flex-wrap flex-center">
        <button
          type="submit"
          disabled={invalid || submitting}
          className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
        >
          <span>Submit</span>
          {submitting && <span className="ml-3 spinner spinner-white"></span>}
        </button>

        <button
          type="button"
          className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </>
  )
}
